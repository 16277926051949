import { MoreOutlined, SearchOutlined, TagOutlined } from '@ant-design/icons';
import {
	Dropdown,
	Input,
	Menu,
	Modal,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import useFetchList from '../../hooks/useFetchList';
import { VoucherProps } from '../../types/voucher.type';

const GenerateVoucher = () => {
	const history = useHistory();
	const { Option } = Select;

	const [isLoadingAction, setIsLoadingAction] = useState(false);
	const [willBeDeleted, setWillBeDeleted] = useState<VoucherProps>();
	const [tmpDataUpdateActiveVoucher, setTmpDataUpdateActiveVoucher] =
		useState<VoucherProps>();
	const [searchName, setSearchName] = useState('');
	const [searchNameDebouced] = useDebounce(searchName, 500);
	const [prefixSearch, setPrefixSearch] = useState('');
	const [prefixDebouced] = useDebounce(prefixSearch, 500);

	const { isLoading, data, pagination, setSearch, setQuery, changePage } =
		useFetchList<VoucherProps>({
			endpoint: 'voucher-generate',
			limit: 0,
			initialQuery: {
				isPrivate: true,
			},
		});

	const handleCreateUser = () => {
		history.push('/voucher-generate/add');
	};

	const handleClickDetail = (e: VoucherProps) => {
		history.push(`/voucher-generate/${e.voucherEventId}`);
	};

	const handleClickEdit = (e: VoucherProps) => {
		history.push(`/voucher-generate/${e.voucherEventId}/edit`);
	};

	const handleClickUpdateActiveVoucher = async () => {
		if (tmpDataUpdateActiveVoucher) {
			setIsLoadingAction(true);
			await httpRequest.patch(
				'/voucher-generate/' + tmpDataUpdateActiveVoucher.voucherEventId,
				{
					isPublished: tmpDataUpdateActiveVoucher.isPublished ? false : true,
				},
			);
			setTmpDataUpdateActiveVoucher(undefined);
			setIsLoadingAction(false);

			setQuery((prevQuery) => ({
				...prevQuery,
				page: pagination.page,
			}));
		}
	};

	const handleClickDelete = async () => {
		if (willBeDeleted) {
			setIsLoadingAction(true);
			await httpRequest.delete(
				`/voucher-generate/${willBeDeleted.voucherEventId}`,
			);
			setWillBeDeleted(undefined);
			setIsLoadingAction(false);

			setQuery((prevQuery) => ({
				...prevQuery,
				page: pagination.page,
			}));
		}
	};

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const handleSearchPrefix = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setPrefixSearch(value);
		setQuery((oldVal) => ({ ...oldVal, prefix: value }));
	};

	useEffect(() => {
		handleSearchPrefix({ target: { value: prefixSearch } } as any);
	}, [prefixDebouced]);

	useEffect(() => {
		setSearch(searchNameDebouced);
	}, [searchNameDebouced]);

	const columns: ColumnsType<VoucherProps> = [
		{
			title: 'VOUCHER NAME',
			key: 'voucherName',
			dataIndex: 'voucherName',
			render: (text: string, record: VoucherProps) => {
				return (
					<div className="table-link" onClick={() => handleClickDetail(record)}>
						{record.voucherName}
					</div>
				);
			},
		},
		{
			title: 'VOUCHER CODE PREFIX',
			key: 'prefix',
			dataIndex: 'prefix',
			render: (text: string, record: VoucherProps) => {
				return <div>{record.prefix}</div>;
			},
		},
		{
			title: 'STATUS',
			key: 'isPublished',
			dataIndex: 'isPublished',
			width: 150,
			render: (isActive: any, record: VoucherProps) => (
				<>
					<Switch
						loading={isLoadingAction}
						checked={isActive}
						onChange={() => {
							setTmpDataUpdateActiveVoucher(record);
						}}
					/>
				</>
			),
		},
		{
			title: 'VOUCHER STATUS',
			key: 'voucherStatus',
			dataIndex: 'voucherStatus',
			render: (text: string, record: VoucherProps) => {
				const style: React.CSSProperties = {
					padding: '3px 10px',
					borderRadius: '7px',
					border: '1px solid',
					display: 'inline-block',
					width: '160px',
					textAlign: 'center',
				};

				if (text == 'Available' || text == 'available') {
					return (
						<div
							style={{
								...style,
								color: '#56C288',
								backgroundColor: '#F5FFF9',
								borderColor: '#56C288',
							}}
						>
							{text}
						</div>
					);
				} else if (text == 'Pending' || text == 'pending') {
					return (
						<div
							style={{
								...style,
								color: '#FAB347',
								backgroundColor: '#FFF9EF',
								borderColor: '#FAB347',
							}}
						>
							{text}
						</div>
					);
				} else if (text == 'Expired' || text == 'expired') {
					return (
						<div
							style={{
								...style,
								color: '#F23459',
								backgroundColor: '#FFF9FB',
								borderColor: '#F23459',
							}}
						>
							{text}
						</div>
					);
				} else if (text == 'Used' || text == 'used') {
					return (
						<div
							style={{
								...style,
								color: '#F23459',
								backgroundColor: '#FFF9FB',
								borderColor: '#F23459',
							}}
						>
							{text}
						</div>
					);
				}
			},
		},
		{
			title: 'CREATED',
			dataIndex: 'validStartAt',
			key: 'validStartAt',
			width: 180,
			render: (val) => <div>{moment(val).format('MMMM DD, YYYY, HH:mm')}</div>,
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: VoucherProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: VoucherProps) => (
		<Menu
			onClick={({ key }) => {
				if (key === 'edit') {
					handleClickEdit(record);
				} else if (key === 'delete') {
					setWillBeDeleted(record);
				} else if (key === 'detail') {
					handleClickDetail(record);
				}
			}}
		>
			<Menu.Item key="edit">Edit</Menu.Item>
			<Menu.Item key="detail">Detail</Menu.Item>
			<Menu.Item key="delete">Delete</Menu.Item>
		</Menu>
	);

	if (data.length === 0) {
		return (
			<div>
				<HeaderSection
					icon={<TagOutlined />}
					title="Generate Voucher"
					subtitle="Manage your generate voucher data"
					rightAction={
						<Space>
							<AppButton type="primary" onClick={handleCreateUser}>
								Generate Vouchers
							</AppButton>
						</Space>
					}
				/>

				<ContainerFilter>
					<Input
						size="large"
						placeholder="Search vouchers name"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => setSearchName(e.target.value)}
					/>
					<Input
						size="large"
						placeholder="Search voucher code prefix"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => setPrefixSearch(e.target.value)}
						style={{ width: 550 }}
					/>
					<Select
						size="large"
						allowClear
						style={{ width: 160 }}
						onChange={handleChangeStatus}
						placeholder="Status"
					>
						<Option value="all">All</Option>
						<Option value="Published">Published</Option>
						<Option value="Unpublished">Unpublished</Option>
					</Select>
				</ContainerFilter>

				<Table
					loading={isLoading}
					columns={columns}
					dataSource={data}
					pagination={false}
				/>

				<Modal
					title="Confirmation"
					visible={!!tmpDataUpdateActiveVoucher}
					onOk={handleClickUpdateActiveVoucher}
					onCancel={() => {
						setTmpDataUpdateActiveVoucher(undefined);
					}}
					okText="Yes"
					confirmLoading={isLoadingAction}
					okButtonProps={{ type: 'primary' }}
				>
					<p>
						Are you sure want to change voucher status to{' '}
						<b>
							{tmpDataUpdateActiveVoucher?.isPublished
								? 'Unpublished'
								: 'Published'}
						</b>
						?
					</p>
				</Modal>

				<Modal
					title="Confirmation"
					visible={!!willBeDeleted}
					onOk={handleClickDelete}
					onCancel={() => {
						setWillBeDeleted(undefined);
					}}
					okText="Yes"
					confirmLoading={isLoadingAction}
					okButtonProps={{ type: 'primary' }}
				>
					<p>Are you sure want to delete this data?</p>
				</Modal>
			</div>
		);
	} else {
		return (
			<div>
				<HeaderSection
					icon={<TagOutlined />}
					title="Generate Vouchers"
					subtitle="Manage your Vouchers data"
					rightAction={
						<Space>
							<AppButton type="primary" onClick={handleCreateUser}>
								Generate Vouchers
							</AppButton>
						</Space>
					}
				/>

				<ContainerFilter>
					<Input
						size="large"
						placeholder="Search vouchers name"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => setSearchName(e.target.value)}
					/>
					<Input
						size="large"
						placeholder="Search voucher code prefix"
						prefix={<SearchOutlined />}
						allowClear
						onChange={(e) => setPrefixSearch(e.target.value)}
						style={{ width: 550 }}
					/>
					<Select
						size="large"
						allowClear
						style={{ width: 250 }}
						onChange={handleChangeStatus}
						placeholder="Status"
					>
						<Option value="all">All</Option>
						<Option value="active">Published</Option>
						<Option value="inactive">Unpublished</Option>
					</Select>
				</ContainerFilter>

				<Table
					loading={isLoading}
					columns={columns}
					dataSource={data}
					pagination={false}
				/>

				{data.length !== 0 ? (
					<>
						<CustomPagination
							current={pagination.page}
							total={pagination.totalData}
							defaultPageSize={pagination.perPage}
							pageSizeOptions={['25', '50', '100']}
							showSizeChanger={true}
							showTotal={(total, range) =>
								`${range[0]} - ${range[1]} of ${total}`
							}
							onChange={changePage}
							locale={{ items_per_page: '' }}
							responsive={true}
						/>
						<PaginationText>
							<h4>Per Page</h4>
						</PaginationText>
					</>
				) : (
					false
				)}

				<Modal
					title="Confirmation"
					visible={!!tmpDataUpdateActiveVoucher}
					onOk={handleClickUpdateActiveVoucher}
					onCancel={() => {
						setTmpDataUpdateActiveVoucher(undefined);
					}}
					okText="Yes"
					confirmLoading={isLoadingAction}
					okButtonProps={{ type: 'primary' }}
				>
					<p>
						Are you sure want to change voucher status to{' '}
						<b>
							{tmpDataUpdateActiveVoucher?.isPublished
								? 'Unpublished'
								: 'Published'}
						</b>
						?
					</p>
				</Modal>

				<Modal
					title="Confirmation"
					visible={!!willBeDeleted}
					onOk={handleClickDelete}
					onCancel={() => {
						setWillBeDeleted(undefined);
					}}
					okText="Yes"
					confirmLoading={isLoadingAction}
					okButtonProps={{ type: 'primary' }}
				>
					<p>Are you sure want to delete this data?</p>
				</Modal>
			</div>
		);
	}
};

export default GenerateVoucher;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;
