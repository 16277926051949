import {
	DatePicker,
	Divider,
	Form,
	FormInstance,
	Input,
	message,
	Radio,
	Select,
	Space,
	Row,
	Col,
	TimePicker,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import type { Moment } from 'moment';
import moment from 'moment';
import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import FormUploadImage from '../../components/FormUpload/FormUploadImage';
import HeaderSection from '../../components/HeaderSection';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { getErrorMessage } from '../../helpers/errorHandler';
import { generateFormRules } from '../../helpers/formRules';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import {
	EVoucherStatus,
	initialVoucher,
	VoucherItem,
	VoucherProps,
} from '../../types/voucher.type';
import { EbookProps, FetchAllEbookResponse } from '../../types/ebook.type';
import { EPaymentTransactionType } from '../../types/payment.type';
import useFetchList from '../../hooks/useFetchList';
import { useDebounce } from 'use-debounce';

interface IParams {
	voucherEventId: string;
}

interface ILocation {
	voucherEventId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: VoucherProps;
}

interface FetchAllVoucherResponse extends BaseResponseProps {
	payload: {
		results: VoucherProps[];
	};
}

const { Option } = Select;

const quillModules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'link'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['clean'],
	],
};

const quillFormats = [
	'header',
	'bold',
	'italic',
	'underline',
	'link',
	'list',
	'bullet',
];

const GenerateVoucherEdit: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { voucherEventId } = useParams<IParams>();
	const formRef = React.useRef<FormInstance<any>>(null);
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const location = useLocation<ILocation>();
	const [isLoadingAction, setIsLoadingAction] = React.useState<boolean>(false);
	const [voucher, setVoucher] = React.useState<VoucherProps>(initialVoucher);

	const [isSelected, setIsSelected] = React.useState(true);
	const [isSelected2, setIsSelected2] = React.useState(true);
	const [isSelected3, setIsSelected3] = React.useState(true);

	const [data, setData] = React.useState<Array<VoucherProps>>([]);
	const [validateCode, setValidateCode] = React.useState<boolean>(true);

	const [validStartAt, setValidStartAt] = React.useState<Date | undefined>(
		voucher.validStartAt ? undefined : voucher.validStartAt,
	);
	const [validEndAt, setValidEndAt] = React.useState<Date | undefined>(
		voucher.validEndAt ? undefined : voucher.validEndAt,
	);

	const [createdDatePickerValue, setCreatedDatePickerValue] =
		React.useState<Moment | null>(null);
	const [expiredDatePickerValue, setExpiredDatePickerValue] =
		React.useState<Moment | null>(null);

	const [createdTimePickerValue, setCreatedTimePickerValue] =
		React.useState<Moment | null>(null);
	const [expiredTimePickerValue, setExpiredTimePickerValue] =
		React.useState<Moment | null>(null);

	const [isNominal, setIsNominal] = React.useState<boolean>(true);
	const [initialEbookIds, setInitialEbookIds] = React.useState<string[]>([]);
	const [searchBookName, setSearchBookName] = React.useState<string>('');
	const [searchBookNameDebounce] = useDebounce(searchBookName, 500);
	const [minimumTransaction, setMinimumTransaction] = React.useState<number>(0);

	const { data: ebooks, setSearch: setSearchBook } = useFetchList<EbookProps>({
		endpoint: 'ebooks',
		initialQuery: {
			isPublished: 'active',
		},
		limit: 100000,
	});

	useEffect(() => {
		setSearchBook(searchBookNameDebounce);
	}, [searchBookNameDebounce]);

	const handleVoucherStatus = (
		startAt: Date | undefined,
		endAt: Date | undefined,
	) => {
		if (moment().isBefore(startAt)) {
			return EVoucherStatus.PENDING;
		} else if (moment().isAfter(endAt)) {
			return EVoucherStatus.EXPIRED;
		} else if (moment().isBetween(startAt, endAt)) {
			return EVoucherStatus.AVAILABLE;
		} else {
			return undefined;
		}
	};

	const handleValidStartAt = (time: Moment | null) => {
		let date1 = createdDatePickerValue;
		let time1 = time;
		let dateValue1 = moment({
			date: date1?.get('date'),
			month: date1?.month(),
			year: date1?.year(),
		}).format('YYYY-MM-DD');

		// let timeValue1 = moment({
		// 	hour: time1?.hour(),
		// 	minute: time1?.minute(),
		// 	second: time1?.second(),
		// });

		let status: EVoucherStatus;

		let final1 = dateValue1.toString().substring(0, 15) + ' ';

		if (moment().isBefore(validStartAt)) {
			status = EVoucherStatus.PENDING;
		} else if (moment().isAfter(validEndAt)) {
			status = EVoucherStatus.EXPIRED;
		} else if (moment().isBetween(validStartAt, validEndAt)) {
			status = EVoucherStatus.AVAILABLE;
		} else {
			status = voucher.voucherStatus;
		}

		if (moment(time1).isAfter(moment(voucher.validEndAt))) {
			setVoucher({
				...voucher,
				validEndAt: undefined,
			});
			setValidEndAt(undefined);
			formRef.current?.setFieldsValue({
				validEndAt: undefined,
				endAt: undefined,
			});
		}

		formRef.current?.setFieldsValue({
			validStartAt: new Date(final1),
		});

		setVoucher({
			...voucher,
			validStartAt: new Date(final1),
			voucherStatus: status,
		});
	};

	const handleValidEndAt = (time: Moment | null) => {
		let date2 = expiredDatePickerValue;
		let time2 = time;
		let dateValue2 = moment({
			date: date2?.date(),
			month: date2?.month(),
			year: date2?.year(),
		}).format('YYYY-MM-DD');

		// let timeValue2 = moment({
		// 	hour: time2?.hour(),
		// 	minute: time2?.minute(),
		// 	second: time2?.second(),
		// });

		let status;

		let final2 = dateValue2.toString().substring(0, 15) + ' ';

		if (moment().isBefore(validStartAt)) {
			status = EVoucherStatus.PENDING;
		} else if (moment().isAfter(validEndAt)) {
			status = EVoucherStatus.EXPIRED;
		} else if (moment().isBetween(validStartAt, validEndAt)) {
			status = EVoucherStatus.AVAILABLE;
		} else {
			status = EVoucherStatus.USED;
		}

		formRef.current?.setFieldsValue({
			validEndAt: new Date(final2),
		});

		setVoucher({
			...voucher,
			validEndAt: new Date(final2),
			voucherStatus: status,
		});
	};

	const saveValidStartDate = (date: Moment | null) => {
		let date2 = date;
		let dateValue = moment({
			date: date2?.date(),
			month: date2?.month(),
			year: date2?.year(),
		}).format('YYYY-MM-DD');

		let timeValue = moment({
			hour: date2?.hour(),
			minute: date2?.minute(),
			second: date2?.second(),
		});

		let final =
			dateValue.toString().substring(0, 15) +
			' ' +
			timeValue.toDate().toString().substring(16, 24);
		setValidStartAt(new Date(final));
	};

	const saveValidEndDate = (date: Moment | null) => {
		let date2 = date;
		let dateValue = moment({
			date: date2?.date(),
			month: date2?.month(),
			year: date2?.year(),
		}).format('YYYY-MM-DD');

		let timeValue = moment({
			hour: date2?.hour(),
			minute: date2?.minute(),
			second: date2?.second(),
		});

		let final =
			dateValue.toString().substring(0, 15) +
			' ' +
			timeValue.toDate().toString().substring(16, 24);
		setValidEndAt(new Date(final));
	};

	const onCreatedTimePickerChange = (time: Moment | null) => {
		// setCreatedTimePickerValue(time);
		let dateTime = time;
		let timeValue = moment({
			hour: dateTime?.hour(),
			minute: dateTime?.minute(),
			second: dateTime?.second(),
		});
		// setCreatedTimePickerValue(timeValue);

		// setVoucher({
		//   ...voucher,
		//   // validStartAt: createdTimePickerValue ? createdTimePickerValue.toDate() : new Date(),
		//   // validStartAt: timeValue.toDate()
		// })
	};

	const onCreatedDatePickerChange = (date: Moment | null) => {
		// setCreatedDatePickerValue(date);
		// setVoucher({
		//   ...voucher,
		//   validStartAt: createdDatePickerValue ? createdDatePickerValue.toDate() : new Date(),
		// })
		let dateTime = date;
		let dateValue = moment({
			date: dateTime?.date(),
			month: dateTime?.month(),
			year: dateTime?.year(),
		});
		setCreatedDatePickerValue(dateValue);
		handleValidStartAt(date);
	};

	const onExpiredTimePickerChange = (time: Moment | null) => {
		// setExpiredTimePickerValue(time);
		// setVoucher({
		//   ...voucher,
		//   validEndAt: expiredTimePickerValue ? expiredTimePickerValue.toDate() : new Date(),
		// })
		let dateTime = time;
		let timeValue = moment({
			hour: dateTime?.hour(),
			minute: dateTime?.minute(),
			second: dateTime?.second(),
		});
		// setExpiredTimePickerValue(timeValue);
		// console.log(timeValue);
	};

	const onExpiredDatePickerChange = (date: Moment | null) => {
		// setExpiredDatePickerValue(date);
		// setVoucher({
		//   ...voucher,
		//   validEndAt: expiredDatePickerValue ? expiredDatePickerValue.toDate() : new Date(),
		// })
		let dateTime = date;
		let dateValue = moment({
			date: dateTime?.date(),
			month: dateTime?.month(),
			year: dateTime?.year(),
		});
		setExpiredDatePickerValue(dateValue);
	};

	const createVoucher = async (
		props: Omit<
			VoucherProps,
			'createdAt' | 'updatedAt' | 'voucherCode' | 'statusLoading'
		>,
	) => {
		try {
			const {
				voucherName,
				description,
				termAndCondition,
				discountValue,
				maxDiscountValue = 0,
				requirement,
				voucherStatus,
				ebooks,
				discountType,
				totalGenerateVoucher,
				prefix,
			} = props;

			const checkVoucherStatus = handleVoucherStatus(validStartAt, validEndAt);

			const voucherItems = voucher.ebooks?.map((ebook) => ({
				itemId: ebook.ebookId,
				itemType: EPaymentTransactionType.EBOOK,
			}));

			const uppercasedPrefix = prefix.toUpperCase();

			const requestBody = {
				dataEvent: {
					title: voucherName,
					description: description,
					tags: [],
				},
				dataVoucher: {
					prefix: uppercasedPrefix,
					voucherType: 'generate',
					discountType: discountType,
					discountValue: discountValue,
					maxDiscountValue: maxDiscountValue,
					requirement: voucher.requirement.minimumTransaction,
					limitMaxUsePerPerson: 1,
					validStartAt: validStartAt,
					voucherItems: voucherItems,
					voucherStatus: checkVoucherStatus,
					validEndAt: validEndAt,
					totalGenerateVoucher: Number(totalGenerateVoucher),
				},
			};

			setIsLoadingAction(true);
			const res: any = await httpRequest.post('/voucher-generate', requestBody);

			if (res && res.data) {
				console.info('res.data', res.data);
			}

			message.success('Success create ' + voucherName);
			history.push('/voucher-generate');
			setIsLoadingAction(false);
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const updateVoucher = async (
		props: Omit<VoucherProps, 'createdAt' | 'updatedAt' | 'statusLoading'>,
	) => {
		try {
			const checkVoucherStatus = handleVoucherStatus(validStartAt, validEndAt);
			const voucherItems = voucher.ebooks?.map((ebook) => ({
				itemId: ebook.ebookId,
				itemType: EPaymentTransactionType.EBOOK,
			}));
			setIsLoadingAction(true);

			const res: any = {
				...props,
				voucherName: voucher.voucherName,
				description: voucher.description,
				termAndCondition: voucher.termAndCondition,
				discountValue: voucher.discountValue,
				maxDiscountValue: voucher.maxDiscountValue,
				limitMaxUsePerPerson: voucher.limitMaxUsePerPerson,
				requirement: voucher.requirement.minimumTransaction,
				validStartAt: validStartAt,
				validEndAt: validEndAt,
				voucherStatus: checkVoucherStatus,
				voucherType: voucher.voucherType,
				totalGenerateVoucher: voucher.totalGenerateVoucher,
				voucherItems: voucherItems,
			};

			if (voucherEventId) {
				await Promise.all([
					httpRequest.patch('/voucher-generate/' + voucherEventId, res),
				]);
			}

			message.success('Success update ' + props.voucherName);
			history.push('/voucher-generate');
		} catch (error) {
			message.error(getErrorMessage(error));
			setIsLoadingAction(false);
		}
	};

	const handleSubmit = async (values: any) => {
		console.info('values', values);
		if (
			values.validEndAt === undefined ||
			values.validStartAt === undefined ||
			values.endAt === undefined ||
			values.startAt === undefined ||
			values.validEndAt === null ||
			values.validStartAt === null ||
			values.endAt === null ||
			values.startAt === null
		) {
			message.error('Valid date cannot empty');
			return;
		}
		if (voucherEventId) {
			updateVoucher(values);
		} else {
			createVoucher(values);
		}
	};

	const handleChangeEbook = (value: any) => {
		const selectedEbooks = value.map((selectedEbookId: string) => {
			const foundEbook = ebooks.find(
				(ebook) => ebook.ebookId === selectedEbookId,
			);
			return foundEbook;
		});

		setVoucher((oldVal) => {
			return {
				...oldVal,
				ebooks: selectedEbooks,
			};
		});
	};

	const fetchList = async () => {
		const res = await httpRequest.get<FetchAllVoucherResponse>(`/vouchers`);

		const newRes = res.data.payload.results.map((item) => {
			return {
				...item,
			};
		});

		setData(newRes);
	};

	React.useEffect(() => {
		fetchList();
		if (voucherEventId) {
			const fetchVoucher = async () => {
				try {
					setIsLoading(true);

					const res = await httpRequest.get<{
						payload: { results: VoucherProps[] };
					}>(`/voucher-generate?voucherEventId=${voucherEventId}`);

					const voucherDetails = res.data.payload.results[0];

					setVoucher(voucherDetails);
					setIsNominal(voucherDetails.discountType == 'nominal' ? true : false);

					form.setFieldsValue({
						startAt: moment(voucherDetails.validStartAt),
						validStartAt: moment(voucherDetails.validStartAt),
						limitMaxUsePerPerson: voucherDetails.limitMaxUsePerPerson,
					});
					setValidStartAt(voucherDetails.validStartAt);
					setValidEndAt(voucherDetails.validEndAt);
					console.log(voucherDetails);
					if (voucherDetails.limitMaxUsePerPerson === 0) {
						setIsSelected2(false);
					} else {
						setIsSelected2(true);
					}

					const bcDetails = [
						{
							field: 'voucherCode',
							value: voucherDetails.prefix,
							label: voucherDetails.voucherName || '',
						},
					];
					setBreadcrumbDetails(bcDetails);

					const vouchersRes = await httpRequest.get<{
						payload: { results: VoucherProps[] };
					}>(`/vouchers?voucherEventId=${voucherEventId}`);

					const voucherItems = vouchersRes.data.payload.results.flatMap(
						(voucher) => voucher.voucherItems || [],
					);
					const ebookIds = Array.from(
						new Set(
							voucherItems
								.filter((item) => item.ebook)
								.map((item) => item.ebook.ebookId),
						),
					);

					console.log('Ebook IDs:', ebookIds);
					setInitialEbookIds(ebookIds);

					setIsLoading(false);
				} catch (error) {
					setIsLoading(false);
				}
			};
			fetchVoucher();
		}
	}, [voucherEventId, location]);

	const initialFormValues = voucherEventId
		? {
				...voucher,
				ebooks: initialEbookIds,
				validStartAt: moment(voucher.validStartAt),
				startAt: moment(voucher.validStartAt),
				validEndAt: moment(voucher.validEndAt),
				endAt: moment(voucher.validEndAt),
				requirement: Number(voucher.requirement!) > 0 ? 'with' : 'none',
		  }
		: initialVoucher;

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Generate Voucher"
				subtitle="Manage your Vouchers data"
				rightAction={
					<Space>
						<AppButton onClick={() => history.goBack()}>Cancel</AppButton>
						<AppButton
							loading={isLoadingAction}
							type="primary"
							onClick={() => formRef?.current?.submit()}
							disabled={validateCode ? false : true}
						>
							Save
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<Form
					ref={formRef}
					name="voucherForm"
					layout="vertical"
					onFinish={handleSubmit}
					initialValues={initialFormValues}
					autoComplete="off"
				>
					<SectionContent
						groupTitle="Information"
						helpers={[
							{
								title: 'Information',
								content: (
									<div
										style={{
											maxWidth: '80%',
											wordWrap: 'break-word',
											overflow: 'hidden',
											whiteSpace: 'normal',
										}}
									>
										Please Provide information
									</div>
								),
							},
						]}
					>
						<Form.Item
							label="Headline"
							name="voucherName"
							rules={generateFormRules('Headline', ['required'])}
						>
							<Input
								value={voucher.voucherName}
								placeholder="Input headline"
								onChange={function (e) {
									setVoucher({
										...voucher,
										voucherName: e.target.value,
									});
								}}
							/>
						</Form.Item>

						<Form.Item label="Detail Vouchers" name="description" rules={[]}>
							<TextArea
								placeholder="Input detail voucher"
								value={voucher.description}
								onChange={(e) => {
									setVoucher({
										...voucher,
										description: e.target.value,
									});
								}}
							></TextArea>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Status"
							name="isPublished"
							rules={generateFormRules('Status', ['required'])}
						>
							<Radio.Group value={voucher.isPublished}>
								<CustomRadio value={true}>Published</CustomRadio>
								<CustomRadio value={false}>Unpublished</CustomRadio>
							</Radio.Group>
						</Form.Item>
					</SectionContent>

					<Divider style={{ width: '100%' }} />
					<SectionContent
						groupTitle="Generate Setting"
						helpers={[
							{
								title: 'Information',
								content:
									'Enter all required details for the voucher code, Make sure the information is accurate to ensure the voucher works',
							},
						]}
					>
						<Form.Item
							label="Total Generate Voucher"
							name="totalGenerateVoucher"
						>
							<Input
								type="number"
								min="1"
								onChange={(e) => {
									const value = parseInt(e.target.value);

									if (!isNaN(value)) {
										setVoucher({
											...voucher,
											totalGenerateVoucher: value,
										});
									}
								}}
								placeholder="Input total generate voucher"
								value={voucher.totalGenerateVoucher}
								width={'100%'}
								disabled={voucherEventId ? true : false}
							/>
						</Form.Item>
						<Form.Item
							label="Generate To E-Book"
							name="ebooks"
							rules={generateFormRules('E-Book', ['required'])}
						>
							<Select
								mode="multiple"
								allowClear
								style={{ width: '100%' }}
								placeholder="Please select"
								onChange={handleChangeEbook}
								onSearch={(e) => {
									setSearchBookName(e);
								}}
								value={
									voucher.ebooks
										? voucher.ebooks.map((item) => item.ebookId || '')
										: []
								}
								filterOption={false}
							>
								{ebooks.map((ebook, index) => {
									return (
										<Option key={`ebook${index}`} value={String(ebook.ebookId)}>
											{ebook.title}
										</Option>
									);
								})}
							</Select>
						</Form.Item>
					</SectionContent>

					<Divider style={{ width: '100%' }} />
					<SectionContent
						groupTitle="Discount Setting"
						helpers={[
							{
								title: 'Information',
								content:
									'Enter all required details for the voucher code, Make sure the information is accurate to ensure the voucher works',
							},
						]}
					>
						<Form.Item
							label="Discount Prefix Code"
							name="prefix"
							rules={generateFormRules('Discount Prefix Code', ['required'])}
							help="Enter a unique code for the prefix that will be placed in each code. e.g. 'FLASH'"
						>
							<Input
								onChange={function (e) {
									data.forEach((item) => {
										if (item.voucherCode == e.target.value) {
											alert('Voucher Code is already exist!');
											setValidateCode(false);
										} else {
											setValidateCode(true);
											setVoucher({
												...voucher,
												voucherCode: e.target.value,
											});
										}
									});
								}}
								value={voucher.voucherCode}
								placeholder="Input code"
								disabled={voucherEventId ? true : false}
							/>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Discount Type"
							name="discountType"
							rules={[]}
						>
							<Radio.Group value={voucher.discountType}>
								<CustomRadio
									value={'percentage'}
									onChange={() => {
										setIsNominal(false);
									}}
								>
									Percentage
								</CustomRadio>
								<CustomRadio
									value={'nominal'}
									onChange={() => {
										setIsNominal(true);
									}}
								>
									Nominal
								</CustomRadio>
							</Radio.Group>
						</Form.Item>

						<Form.Item
							label="Discount Value"
							name="discountValue"
							rules={generateFormRules('Discount Value', ['required'])}
						>
							<Input
								type="number"
								min="0"
								value={voucher.discountValue}
								onChange={(e) => {
									const inputValue = parseFloat(e.target.value);
									if (!isNaN(inputValue) && inputValue > 0) {
										setVoucher({
											...voucher,
											discountValue: inputValue,
										});
									} else {
										form.setFields([
											{
												name: 'discountValue',

												errors: ['Discount value must be greater than 0'],
											},
										]);
									}
								}}
								prefix={isNominal && '₱'}
								suffix={!isNominal && '%'}
								placeholder="Input discount value"
								width={'100%'}
							/>
						</Form.Item>

						{!isNominal && (
							<Form.Item
								label="Max Discount Value"
								name="maxDiscountValue"
								rules={generateFormRules('Max Discount Value', ['required'])}
							>
								<Input
									type="number"
									min="0"
									value={voucher.maxDiscountValue}
									onChange={(e) => {
										const inputValue = parseFloat(e.target.value);
										if (!isNaN(inputValue) && inputValue > 0) {
											setVoucher({
												...voucher,
												maxDiscountValue: inputValue,
											});
										} else {
											form.setFields([
												{
													name: 'maxDiscountValue',
													errors: ['Discount value must be greater than 0'],
												},
											]);
										}
									}}
									placeholder="Input Max discount value"
									width={'100%'}
								/>
							</Form.Item>
						)}

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Minimum Transaction"
							name="requirement"
							rules={generateFormRules('Minimum Transaction', ['required'])}
						>
							<Radio.Group
								value={Number(voucher.requirement!) > 0 ? 'with' : 'none'}
								onChange={(e) => {
									const value = e.target.value;
									if (value === 'none') {
										setMinimumTransaction(0);
										setVoucher({
											...voucher,
											requirement: {
												minimumTransaction: 0,
											},
										});
									} else {
										setMinimumTransaction(0);
										setVoucher({
											...voucher,
											requirement: {
												minimumTransaction: 0,
											},
										});
									}
								}}
							>
								<CustomRadio value="with" style={{ margin: '0' }}>
									<Input
										onChange={(e) => {
											const value = e.target.value;
											const parsedValue = parseInt(value);
											if (!isNaN(parsedValue) && parsedValue > 0) {
												setMinimumTransaction(parsedValue);
												setVoucher({
													...voucher,
													requirement: {
														minimumTransaction: parsedValue,
													},
												});
												formRef.current?.setFieldsValue({
													requirement: 'with',
												});
											} else {
												setMinimumTransaction(0);
												setVoucher({
													...voucher,
													requirement: {
														minimumTransaction: 0,
													},
												});
											}
										}}
										disabled={
											formRef.current?.getFieldValue('requirement') === 'none'
										}
										prefix="₱"
										placeholder="Input minimum transaction"
										style={{ width: '18vw' }}
										value={
											minimumTransaction! > 0
												? minimumTransaction?.toString()
												: ''
										}
									/>
								</CustomRadio>
								<CustomRadio value="none" style={{ margin: '0' }}>
									No minimum transaction
								</CustomRadio>
							</Radio.Group>
						</Form.Item>

						<Form.Item name="validStartAt" hidden>
							<div>
								<DatePicker
									value={
										voucher.validStartAt
											? moment(voucher.validStartAt)
											: undefined
									}
								/>
							</div>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Valid From"
							name="startAt"
							rules={generateFormRules('Valid From', ['required'])}
						>
							<CustomDatePicker
								defaultValue={
									voucher.validStartAt
										? moment(voucher.validStartAt)
										: undefined
								}
								format={'DD/MM/YYYY HH:mm'}
								showTime={{ format: 'HH:mm' }}
								style={{ width: '55%' }}
								placeholder="Select date and time"
								onChange={(e) => {
									handleValidStartAt(e);
									onCreatedDatePickerChange(e);
								}}
								onSelect={(e) => {
									saveValidStartDate(e);
									handleValidStartAt(e);
								}}
								size="large"
							/>
						</Form.Item>
						<Form.Item name="validEndAt" hidden>
							<div>
								<DatePicker
									value={
										voucher.validEndAt ? moment(voucher.validEndAt) : undefined
									}
								/>
							</div>
						</Form.Item>

						<Form.Item
							style={{
								paddingTop: '2%',
							}}
							label="Valid Until"
							name="endAt"
							rules={generateFormRules('Valid Until', ['required'])}
						>
							<CustomDatePicker
								value={
									voucher.validEndAt !== undefined
										? moment(voucher.validEndAt)
										: undefined
								}
								showTime={{ format: 'HH:mm' }}
								format={'DD/MM/YYYY HH:mm'}
								style={{ width: '55%' }}
								placeholder="Select date and time"
								onChange={(e) => {
									onExpiredDatePickerChange(e);
									handleValidEndAt(e);
								}}
								allowClear
								onSelect={(e) => {
									saveValidEndDate(e);
									handleValidEndAt(e);
								}}
								disabledDate={(current) => {
									return current.isBefore(moment(validStartAt));
								}}
								size="large"
							/>
						</Form.Item>
					</SectionContent>
				</Form>
			</AppCard>
		</div>
	);
};

export default GenerateVoucherEdit;

const CustomRadio = styled(Radio)`
	margin-right: 5rem;
	.ant-radio-checked .ant-radio-inner {
		border-color: red;
		box-shadow: none;
	}
	.ant-radio:hover .ant-radio-inner {
		background-color: white;
	}
	.ant-radio-checked .ant-radio-inner:after {
		background-color: red;
	}
	.ant-form-item-explain {
		display: none;
	}
	.radio-button-container {
		margin-top: 32px;
	}
`;

const CustomDatePicker = styled(DatePicker)`
	margin-right: 10px;
	.ant-picker .ant-picker-input,
	.ant-picker.ant-picker-range {
		display: flex;
		flex-direction: row-reverse;
	}
	.ant-picker .ant-picker-input span.ant-picker-suffix,
	.ant-picker.ant-picker-range span.ant-picker-suffix {
		margin-left: 0;
		margin-right: 5px;
	}
`;
